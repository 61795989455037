const en = {
    name:"Jamie Taylor",
    home: "Home",
    work: "Work" ,
    posts: "Posts",
    resume: "Resume",
    logoAlt: "Jamie Taylor's Ramen Bowl Logo", 
    homePage:{
        welcomeBar: "Hello, I'm a web & app developer based in Richmond, VA!",
        titles: "Solution Architect / Developer / Designer",
        aboutTitle: "About",
        profileDesc: "Me and a bear",
        about: "Hello! My name is Jamie and I'm a full stack web and app developer based outta Richmond, VA. In my years in marketing I've worn a lot of hats, including designer, developer, systems engineer, photographer, videographer, project manager, product manager, and solutions architect and I love every minute of it! When I'm not online, you can find me on my bike, on my drumset, or making something on the stove top.",
        workCTA: "Check out my work",
        timeLineTitle: "Bio",
        y1982: "Born & Raised",
        y1998: "Built my first website (RIP Geocities.com)",
        y2000: "Started first job as we web designer in Portsmouth, VA at a company called SuiteSites.com",
        y2008: {
            part1: "Created ",
            part2: "Htmyell Design & Development",
            part3: " freelance company",
        },
        iHeartTitle: "I",
        iHeart: "",
        iHeart: {
            part1: "Design, Cooking, Development, Music, ",
            part2: "Drumming",
            part3: ", Cycling, Language Learning, Photography",
        },
        socialMediaTitle: "Socials",
        siteTitle: "Site",
        siteInfo: {
            part1: "This website was lovingly built using React Native, NativeBase, Threejs, Expo, Supabase, and a few other technologies. You can see the entire source at ",
            part2: "Github",
            part3: " ",
        },   
    },
    workPage: {
        workIntro: "Featured work, personal projects, and other such spectacles.",
        resumeCTA: "Check out my resume",
    },
    postsPage: {
        postsIntro: "Latest posts & happenings in my life.",
        resumeCTA: "Check out my resume",
        externalToolTip: "Opens offsite in new tab",
    },
    workDetailsPage: {
        dateTitle: "Date",
        openURLCTA: "Check it out",
        backCTA: "Back to Work",
    },
    e404: {
        welcomeBar: "Something went wrong ",
        pageTitle: "Error",
        pageContent: "Sorry, the page your looking for doesn't exist.",
    }, 
};

const ja = {
    name:"ジェイミー・テイラー",
    home: "ホム",
    work: "仕事" ,
    posts: "投稿",
    resume: "履歴書",
    logoAlt: "ジェイミー・テイラーのラーメンボウルのロゴ",
    homePage: {
        welcomeBar: "こんにちは、バージニア州リッチモンドを拠点とするウェブ・アプリ開発者です!",
        titles: "ソリューション・アーキテクト / エンジニア / デザイナー",
        aboutTitle: "身元", 
        profileDesc: "私とくまさん",
        about: "はじめまして! 私の名前はジェイミーです。バージニア州リッチモンドを拠点とするフルスタックのウェブ・アプリ開発者です。デザイナー、デベロッパー、システムエンジニア、カメラマン、ビデオグラファー、プロジェクトマネージャー、プロダクトマネージャー、ソリューションアーキテクトなど、さまざまな仕事をしてきました！オンラインでないときは、自転車に乗っていたり、ドラムセットを叩いていたり、ストーブの上で何かを作っていたりします。よろしくお願いします!",
        workCTA: "作品を見てください",
        timeLineTitle: "プロフィール",
        y1982: "生まれ育ちました",
        y1998: "初めてウェブサイトを作りました",
        y2000: "バージニア州ポーツマスのSuiteSites.comという会社でウェブデザイナーとして最初の仕事を始めました",
        y2008: {
            part1: "フリーランスの会社",
            part2: "Htmyell Design and Development",
            part3: "を設立しました",
        },        
        iHeartTitle: "私",
        iHeart: {
            part1: "デザイン、料理、開発、音楽、",
            part2: "ドラム",
            part3: "、サイクリング、語学学習、写真が好きです",
        },        
        socialMediaTitle: "SNS",
        siteTitle: "ホムペ",
        siteInfo: {
            part1: "このウェブサイトは、React Native、NativeBase、Threejs、Expo、Supabase、その他いくつかの技術を使って愛情込めて作られました。",
            part2: "Github",
            part3: "でソース全体を見ることができます。",
        },   
    },
    workPage: {
        workIntro: "このページでは、私の作品や個人的なプロジェクト、その他取り組んでいることを紹介します。",
        resumeCTA: "履歴書を見てください",
    },
    postsPage: {
        postsIntro: "最新のブログ記事と、私の人生で起きているその他のことです。",
        resumeCTA: "履歴書を見てください",
        externalToolTip: "新しいタブでサイト外を開きます",
    },
    workDetailsPage: {
        dateTitle: "日付",
        openURLCTA: "ウェブサイトをご覧ください",
        backCTA: "戻ります",
    },
    e404: {
        welcomeBar: "何かが間違ってしまった！",
        pageTitle: "エラー",
        pageContent: "申し訳ありませんが、お探しのページは存在しません。",
    },      
};

export { en, ja };